import styledCSS from '@emotion/styled';

export const CalenderContainer = styledCSS.div`
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
    padding: 16px;
    border: 1px solid rgba(224, 224, 224, 1);
    border-bottom-width: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .fc-theme-standard td, .fc-theme-standard th {
    border: none;
  }
  .fc .fc-scrollgrid {
    border: 1px solid rgba(224, 224, 224, 1);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .fc-col-header-cell {
    padding-top: 14px;
    padding-bottom: 28px;
  }
  .fc .fc-button-primary {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid rgba(228, 230, 231, 0.60);
    box-shadow: 0px 1px 5px -1px rgba(17, 12, 34, 0.10);
    padding: 10px;
  }
  .fc .fc-button-primary {
    color: #000;
  }
  .fc td {
    height: 48px;
  }
  .fc-toolbar-title {
    font-size: 16px;
    font-weight: 600;
  }
  .fc-daygrid-day-top {
    justify-content: center;
    font-size: 14px;
  }
  .fc-daygrid-day-events {
    display: none;
  }
  ${(props) => props.sx ?? ''}
`;
