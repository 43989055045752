import React from 'react';

import PropsTypes from 'prop-types';

import {Typography} from '@mui/material';

const SubtitleField = ({children, sx}) => {
  return (
    <Typography
      component='h6'
      sx={{
        fontWeight: '600',
        lineHeight: 1,
        fontSize: 14,
        py: {xs: 1, md: 0},
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export default SubtitleField;

SubtitleField.propTypes = {
  children: PropsTypes.oneOfType([PropsTypes.element, PropsTypes.string]),
  sx: PropsTypes.object,
};
