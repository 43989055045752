import * as yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const orderValidationSchema = yup.object({
  order_date: yup.string().nullable().required('Field wajib diisi'),
  order_type: yup.string().nullable().required('Field wajib diisi'),
  order_term: yup.string().nullable().required('Field wajib diisi'),
  sender_information: yup.object().when('order_term', {
    is: (value) => value == 1 || value == 2,
    then: yup.object().shape({
      company_name: yup.string().required('Field wajib diisi'),
      pic_name: yup.string().required('Field wajib diisi'),
      email: yup
        .string()
        .required('Field wajib diisi')
        .email('Format e-mail salah'),
      phone_number: yup
        .string()
        .required('Field wajib diisi')
        .matches(phoneRegExp, 'Nomor telepon tidak valid'),
      address: yup.string().required('Field wajib diisi'),
      district_id: yup.object().nullable().required('Field wajib diisi'),
    }),
    otherwise: yup.object().shape({
      company_name: yup.string().required('Field wajib diisi'),
      pic_name: yup.string().required('Field wajib diisi'),
      email: yup
        .string()
        .required('Field wajib diisi')
        .email('Format e-mail salah'),
      phone_number: yup
        .string()
        .required('Field wajib diisi')
        .matches(phoneRegExp, 'Nomor telepon tidak valid'),
      address: yup.string(),
      district_id: yup.object().nullable(),
    }),
  }),
  receipt_information: yup.object().when('order_term', {
    is: (value) => value == 1 || value == 3,
    then: yup.object().shape({
      company_name: yup.string().required('Field wajib diisi'),
      pic_name: yup.string().required('Field wajib diisi'),
      email: yup
        .string()
        .required('Field wajib diisi')
        .email('Format e-mail salah'),
      phone_number: yup
        .string()
        .required('Field wajib diisi')
        .matches(phoneRegExp, 'Nomor telepon tidak valid'),
      address: yup.string().required('Field wajib diisi'),
      district_id: yup.object().nullable().required('Field wajib diisi'),
    }),
    otherwise: yup.object().shape({
      company_name: yup.string().required('Field wajib diisi'),
      pic_name: yup.string().required('Field wajib diisi'),
      email: yup
        .string()
        .required('Field wajib diisi')
        .email('Format e-mail salah'),
      phone_number: yup
        .string()
        .required('Field wajib diisi')
        .matches(phoneRegExp, 'Nomor telepon tidak valid'),
      address: yup.string(),
      district_id: yup.object().nullable(),
    }),
  }),
  etd: yup.string().nullable().required('Field wajib diisi'),
  // eta: yup.string().nullable().required('Field wajib diisi'),
  // ship_schedule_id: yup.object().nullable().required('Field wajib diisi'),
  // delivery_at: yup.string().nullable().required('Field wajib diisi'),
  total_container: yup.string(),
  need_quarantine: yup.bool().required('Field wajib dipilih'),
  quarantine_value: yup.string().when('need_quarantine', {
    is: true,
    then: yup.string().nullable().required('Field wajib diisi'),
    otherwise: yup.string().nullable(),
  }),
  sender_commodities: yup.array().when('order_type', (order_type, schema) =>
    schema.of(
      yup.object({
        commodity_id: yup.object().nullable().required('Field wajib diisi'),
        collie:
          order_type == 1
            ? yup.string().required('Field wajib diisi')
            : yup.string(),
        qty: yup.string().required('Field wajib diisi'),
        long:
          order_type == 2
            ? yup.string().required('Field wajib diisi')
            : yup.string(),
        width:
          order_type == 2
            ? yup.string().required('Field wajib diisi')
            : yup.string(),
        tall:
          order_type == 2
            ? yup.string().required('Field wajib diisi')
            : yup.string(),
        container_type:
          order_type == 1
            ? yup.object().nullable().required('Field wajib diisi')
            : yup.object().nullable(),
        quarantined: yup.bool(),
        quarantine_weight: yup.string().required('Field wajib diisi'),
      }),
    ),
  ),
  origin_id: yup.object().nullable().required('Field wajib diisi'),
  destination_id: yup.object().nullable().required('Field wajib diisi'),
  pickup_detail_type: yup.string().nullable().required('Field wajib diisi'),
  payment_detail_type: yup.string().nullable().required('Field wajib diisi'),
  payment_detail_company_name: yup.string().when('payment_detail_type', {
    is: (value) => value == 3,
    then: yup.string().required('Field wajib diisi'),
    otherwise: yup.string(),
  }),
  payment_detail_pic_name: yup.string().when('payment_detail_type', {
    is: (value) => value == 3,
    then: yup.string().required('Field wajib diisi'),
    otherwise: yup.string(),
  }),
  payment_detail_email: yup.string().when('payment_detail_type', {
    is: (value) => value == 3,
    then: yup
      .string()
      .required('Field wajib diisi')
      .email('Format e-mail salah'),
    otherwise: yup.string(),
  }),
  payment_detail_phone_number: yup.string().when('payment_detail_type', {
    is: (value) => value == 3,
    then: yup
      .string()
      .required('Field wajib diisi')
      .matches(phoneRegExp, 'Nomor telepon tidak valid'),
    otherwise: yup.string(),
  }),
  payment_detail_address: yup.string().when('payment_detail_type', {
    is: (value) => value == 3,
    then: yup.string().required('Field wajib diisi'),
    otherwise: yup.string(),
  }),
  unload_detail_type: yup.string().when('order_type', {
    is: (value) => value == 1,
    then: yup.string().nullable().required('Field wajib diisi'),
    otherwise: yup.string().nullable(),
  }),
  insurance_value: yup.string().when('need_insurance', {
    is: true,
    then: yup.string().nullable().required('Field wajib diisi'),
    otherwise: yup.string().nullable(),
  }),
});

export default orderValidationSchema;
