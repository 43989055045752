import React from 'react';
import {ScrollElement} from 'react-scroll';

import PropTypes from 'prop-types';

const Element = ({parentBindings, className, children, ...props}) => {
  return (
    <section
      {...props}
      ref={(el) => {
        parentBindings.domNode = el;
      }}
    >
      <div className={className}>{children}</div>
    </section>
  );
};

export default ScrollElement(Element);

Element.propTypes = {
  parentBindings: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};
