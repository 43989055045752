import React from 'react';
import routeUrl from 'shared/constants/RouteUrl';

const Error401 = React.lazy(() => import('./Error401'));
const Error403 = React.lazy(() => import('./Error403'));
const Error404 = React.lazy(() => import('./Error404'));
const Error500 = React.lazy(() => import('./Error500'));
const Error503 = React.lazy(() => import('./Error503'));
const ComingSoon = React.lazy(() => import('./ComingSoon'));
const Maintenance = React.lazy(() => import('./Maintenance'));

export const errorPagesConfigs = [
  {
    path: routeUrl.error401,
    element: <Error401 />,
  },
  {
    path: routeUrl.error403,
    element: <Error403 />,
  },
  {
    path: routeUrl.error404,
    element: <Error404 />,
  },
  {
    path: routeUrl.error500,
    element: <Error500 />,
  },
  {
    path: routeUrl.error503,
    element: <Error503 />,
  },
  {
    path: routeUrl.comingSoon,
    element: <ComingSoon />,
  },
  {
    path: routeUrl.maintenance,
    element: <Maintenance />,
  },
];
