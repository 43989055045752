import React from 'react';

import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

import {Box, IconButton, Stack} from '@mui/material';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';

import {onGetCommodityData, onGetVehicleTypeData} from 'redux/actions';

import SelectAsync from '../SelectAsync';
import TextField from '../TextField';
import Checkbox from '../Checkbox';

const CommodityField = ({
  value,
  index,
  lengthForm,
  orderType,
  onAddBtnPress,
  onDeleteBtnPress,
}) => {
  const dispatch = useDispatch();

  const [defaultCommodityOpt, setDefaultCommodityOpt] = React.useState([]);
  const [defaultCommodityTypeOpt, setDefaultCommodityTypeOpt] = React.useState(
    [],
  );

  const defaultCommodityValue = value?.commodity
    ? {
        value: JSON.stringify({
          id: value.commodity?.id,
          unit: value?.commodity?.unit,
        }),
        label: value.commodity?.name,
      }
    : null;

  const defaultCommodityTypeValue = value?.container_type
    ? {
        value: value.container_type,
        label:
          defaultCommodityTypeOpt.find(
            (item) => item.value === value?.container_type,
          )?.label ?? '',
      }
    : null;

  React.useEffect(() => {
    const getDefaultCommodityOptions = async () => {
      if (defaultCommodityOpt.length === 0) {
        const response = await dispatch(onGetCommodityData());
        const {rows} = response?.data ?? {};

        setDefaultCommodityOpt(
          rows
            .filter(({status}) => status)
            .map((item) => ({
              label: `${item.name}`,
              value: JSON.stringify({id: item.id, unit: item.unit}),
            })),
        );
      }
    };

    const getDefaultCommodityTypeOptions = async () => {
      if (defaultCommodityOpt.length === 0) {
        const response = await dispatch(onGetVehicleTypeData());
        const data = response?.data ?? [];

        setDefaultCommodityTypeOpt(
          data
            .filter(({status}) => status)
            .map((item) => ({label: item.name, value: item.index})),
        );
      }
    };

    getDefaultCommodityOptions();
    getDefaultCommodityTypeOptions();
  }, []);

  const handleFetchCommodity = async (value, callback) => {
    const response = await dispatch(onGetCommodityData({search: value}));
    const {rows} = response?.data ?? {};

    callback(
      rows
        .filter(({status}) => status)
        .map((item) => ({
          label: `${item.name}`,
          value: JSON.stringify({id: item.id, unit: item.unit}),
        })),
    );
  };

  const handleFetchCommodityType = async (value, callback) => {
    const response = await dispatch(onGetVehicleTypeData({search: value}));
    const data = response?.data ?? [];

    callback(
      data
        .filter(({status}) => status)
        .map((item) => ({label: item.name, value: item.index})),
    );
  };

  return (
    <Stack
      direction={{xs: 'column', lg: 'row'}}
      spacing={4}
      flex={1}
      alignItems={{lg: 'start'}}
    >
      <Stack flex={1}>
        <SelectAsync
          name={`sender_commodities[${index}].commodity_id`}
          label='Tipe Komoditas'
          fetchOptions={handleFetchCommodity}
          defaultValue={defaultCommodityValue}
          defaultOptions={defaultCommodityOpt}
        />
      </Stack>
      <Stack direction={{xs: 'column', md: 'row'}} spacing={4} flex={3}>
        {orderType == 1 && (
          <SelectAsync
            name={`sender_commodities[${index}].container_type`}
            label='Tipe Kontainer'
            fetchOptions={handleFetchCommodityType}
            defaultValue={defaultCommodityTypeValue}
            defaultOptions={defaultCommodityTypeOpt}
          />
        )}
        <TextField
          label={orderType == 1 ? 'Qty' : 'Collie'}
          type='number'
          name={`sender_commodities[${index}].qty`}
        />
        {orderType == 1 && (
          <TextField
            label={'Collie'}
            type='number'
            name={`sender_commodities[${index}].collie`}
          />
        )}
        {orderType == 2 && (
          <>
            <TextField
              label='Panjang'
              endAdornment={orderType == 1 ? 'm' : 'cm'}
              type='number'
              name={`sender_commodities[${index}].long`}
            />
            <TextField
              label='Lebar'
              endAdornment={orderType == 1 ? 'm' : 'cm'}
              type='number'
              name={`sender_commodities[${index}].width`}
            />
            <TextField
              label='Tinggi'
              endAdornment={orderType == 1 ? 'm' : 'cm'}
              type='number'
              name={`sender_commodities[${index}].tall`}
            />
          </>
        )}
        <TextField
          label='Berat'
          endAdornment={'Kg'}
          type='number'
          name={`sender_commodities[${index}].quarantine_weight`}
        />
      </Stack>
      {orderType == 1 && (
        <Stack direction={{xs: 'column', md: 'row'}}>
          <Checkbox
            name={`sender_commodities[${index}].quarantined`}
            label='Karantina'
            isVerticalLabel={true}
          />
        </Stack>
      )}

      <Stack direction={'row'}>
        <Box sx={{flex: 1}} />
        <IconButton onClick={onDeleteBtnPress} disabled={lengthForm === 1}>
          <DeleteOutline sx={{fontSize: 20}} />
        </IconButton>
        <IconButton
          onClick={onAddBtnPress}
          disabled={index + 1 !== lengthForm}
          sx={{
            visibility: index + 1 !== lengthForm ? 'hidden' : 'visible',
          }}
        >
          <AddCircleOutline sx={{fontSize: 20}} color='primary' />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default CommodityField;

CommodityField.propTypes = {
  value: PropTypes.object,
  index: PropTypes.number,
  lengthForm: PropTypes.number,
  onAddBtnPress: PropTypes.func,
  onDeleteBtnPress: PropTypes.func,
  isQuarantined: PropTypes.bool,
  orderType: PropTypes.number,
};
