const createRouteParams = (obj) => {
  if (!obj) return '';

  if (typeof obj === 'string') return obj;

  const objArr = Object.keys(obj);

  return objArr.reduce((acc, item) => {
    if (obj[item] === undefined || obj[item] === null) return acc;

    return `${acc}${acc.length > 0 ? '&' : ''}${item}=${obj[item]}`;
  }, '');
};

export default createRouteParams;
