import * as yup from 'yup';

const invoiceValidationSchema = yup.object({
  payment_method: yup.string().nullable().required('Field wajib diisi'),
  file: yup.array().nullable(),
  paid_amount: yup.string().required('Field wajib diisi'),
  termin: yup.string().nullable().required('Field wajib diisi'),
});

export default invoiceValidationSchema;
