import React from 'react';

import {Navigate} from 'react-router-dom';

import {initialUrl} from 'shared/constants/AppConst';

import {authRouteConfig} from './auth';
import {errorPagesConfigs} from './errorPages';
import {publicRouteConfig} from './publicPages';
import {authorizedRouteConfig} from './authorizedPages';

import Error403 from './errorPages/Error403';

import routeUrl from 'shared/constants/RouteUrl';

const authorizedStructure = {
  fallbackPath: routeUrl.signin,
  unAuthorizedComponent: <Error403 />,
  routes: [...authorizedRouteConfig],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: [...authRouteConfig],
};

const anonymousStructure = {
  routes: [
    ...publicRouteConfig,
    ...errorPagesConfigs.concat([
      {
        path: '*',
        element: <Navigate to={routeUrl.error404} />,
      },
    ]),
  ],
};

export {authorizedStructure, unAuthorizedStructure, anonymousStructure};
