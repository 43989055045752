import React from 'react';

import {useField} from 'formik';

import PropsTypes from 'prop-types';

import {
  FormControl,
  Select as BaseSelect,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import {grey} from '@mui/material/colors';

const Select = ({options = [], label, disabled, sx, ...props}) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';

  const renderOpt = ({label, ...item}, index) => {
    return (
      <MenuItem key={`menu-${index}`} {...item}>
        {label}
      </MenuItem>
    );
  };

  const onChange = (e) => {
    field.onChange(e);

    if (typeof props.onChange === 'function') {
      props.onChange(e);
    }
  };

  return (
    <FormControl sx={{flex: 1, width: '100%'}} fullWidth error={!!errorText}>
      {label && <InputLabel>{label}</InputLabel>}
      <BaseSelect
        {...props}
        {...field}
        onChange={onChange}
        value={props?.value ?? field?.value ?? ''}
        disabled={disabled}
        label={label}
        sx={{
          backgroundColor: !disabled ? '#fff' : grey[200],
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: grey[300],
          },
          ...sx,
        }}
      >
        {(options
          ? options.length > 0
            ? options
            : [{label: 'No options', value: null, disabled: true}]
          : []
        ).map(renderOpt)}
      </BaseSelect>

      {!!errorText && <FormHelperText sx={{ml: 0}}>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default Select;

Select.propTypes = {
  options: PropsTypes.array,
  label: PropsTypes.string,
  sx: PropsTypes.object,
  disabled: PropsTypes.bool,
  onChange: PropsTypes.func,
  value: PropsTypes.oneOfType([PropsTypes.string, PropsTypes.array]),
};
