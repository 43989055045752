import React from 'react';

const Landing = React.lazy(() => import('./Landing'));
const RateCheck = React.lazy(() => import('./RateCheck'));
const Tracking = React.lazy(() => import('./Tracking'));
const TrackingResult = React.lazy(() => import('./TrackingResult'));
const Privacy = React.lazy(() => import('./Privacy'));
const Term = React.lazy(() => import('./Term'));

import routeUrl from 'shared/constants/RouteUrl';

export const publicRouteConfig = [
  {
    path: routeUrl.home,
    element: <Landing />,
  },
  {
    path: routeUrl.rateCheck,
    element: <RateCheck />,
  },
  {
    path: routeUrl.tracking,
    element: <Tracking />,
  },
  {
    path: routeUrl.trackingResult,
    element: <TrackingResult />,
  },
  {
    path: routeUrl.privacy,
    element: <Privacy />,
  },
  {
    path: routeUrl.term,
    element: <Term />,
  },
];
