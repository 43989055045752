import React from 'react';

import {Form, Formik} from 'formik';
import PropTypes from 'prop-types';

import {
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import {grey} from '@mui/material/colors';

import {TextField, RadioField, Dropzone} from 'components';
import {PAYMENT_METHOD} from 'shared/constants/AppDataConst';
import {INVOICE_VALIDATION} from 'shared/validationShema';

const HistoryDetailDialog = ({onSubmit}, ref) => {
  const [config, setConfig] = React.useState({
    id: null,
    price: null,
    show: false,
  });

  React.useImperativeHandle(ref, () => ({
    show: (newConfig) => {
      setConfig({
        ...newConfig,
        show: true,
      });
    },
    hide: handleClose,
  }));

  const handleClose = () => {
    setConfig((prev) => ({...prev, show: false}));
  };

  const handleOnSubmit = (data) => {
    if (typeof onSubmit === 'function') onSubmit(data);
  };

  return (
    <Dialog onClose={handleClose} open={config.show} fullWidth={true}>
      <Formik
        validateOnChange={true}
        onSubmit={handleOnSubmit}
        onReset={handleClose}
        validationSchema={INVOICE_VALIDATION}
        initialValues={{
          payment_method: null,
          paid_amount: 0,
          termin: null,
          file: null,
        }}
      >
        {() => (
          <Form sx={{textAlign: 'left'}} noValidate autoComplete='off'>
            <DialogTitle
              sx={{
                fontSize: 16,
                px: 5,
                fontWeight: 600,
                borderBottom: 1,
                borderColor: grey[300],
              }}
            >
              Tambah Pembayaran
            </DialogTitle>
            <DialogContent sx={{p: 0}}>
              <Stack spacing={4} sx={{p: 5}}>
                <Stack spacing={2}>
                  <Typography component='label'>Termin</Typography>

                  <TextField type='number' name='termin' />
                </Stack>

                <Stack spacing={2}>
                  <Typography component='label'>Metode Pembayaran</Typography>

                  <RadioField name='payment_method' options={PAYMENT_METHOD} />
                </Stack>
                <Stack spacing={2}>
                  <Typography component='label'>Total Bayar</Typography>

                  <TextField
                    type='number'
                    startAdornment='Rp.'
                    name='paid_amount'
                  />
                </Stack>
                <Stack spacing={2}>
                  <Typography component='label'>Bukti Pembayaran</Typography>

                  <Dropzone name='file' />
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions sx={{px: 5, borderTop: 1, borderColor: grey[300]}}>
              <Button type='reset' color='secondary'>
                Batal
              </Button>
              <Button type='submit'>Submit</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default React.forwardRef(HistoryDetailDialog);

HistoryDetailDialog.propTypes = {
  onSubmit: PropTypes.func,
};
