import React from 'react';

import {useDropzone} from 'react-dropzone';
import PropsTypes from 'prop-types';
import {useField} from 'formik';

import {
  Typography,
  Box,
  Grid,
  FormControl,
  FormHelperText,
} from '@mui/material';
import {grey} from '@mui/material/colors';
import ImagePreview from 'components/organisms/ImagePreview';

function Dropzone({disabled, multiple, ...props}) {
  const [field, meta, helper] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': [],
      'application/pdf': [],
    },
    multiple: multiple ?? false,
    disabled,
    onDrop: (acceptedFiles) => {
      helper.setValue([
        ...(multiple ? field?.value ?? [] : []),
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      ]);
    },
  });

  const handleDeleteFile = (index) => () => {
    helper.setValue(
      (field?.value ?? [])
        .filter((_, idx) => index !== idx)
        .map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
    );
  };

  const renderFile = (item, index) => {
    return (
      <Grid item xs={4} sm={3} md={2} key={index}>
        <ImagePreview
          data={item}
          onClose={disabled ? false : handleDeleteFile(index)}
        />
      </Grid>
    );
  };

  React.useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      (field?.value ?? []).forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <FormControl sx={{flex: 1, width: '100%'}} error={!!errorText}>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 10,
          borderWidth: 1,
          borderRadius: 1,
          borderColor: grey[200],
          borderStyle: 'dashed',
          backgroundColor: grey[50],
          color: grey[500],
          outline: 'none',
          transition: 'border .24s ease-in-out',
        }}
        {...getRootProps({className: 'dropzone'})}
      >
        <input name={field.name} {...getInputProps()} />
        <Typography textAlign='center'>
          Seret dan lepaskan berkas di sini, atau klik untuk memilih berkas
        </Typography>
      </Box>

      {!!errorText && <FormHelperText sx={{ml: 0}}>{errorText}</FormHelperText>}

      {(field?.value ?? []).length > 0 && (
        <Grid container mt={4} spacing={3}>
          {(field?.value ?? []).map(renderFile)}
        </Grid>
      )}
    </FormControl>
  );
}

export default Dropzone;

Dropzone.propTypes = {
  disabled: PropsTypes.bool,
  multiple: PropsTypes.bool,
};
