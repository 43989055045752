import React from 'react';

import {useField} from 'formik';

import {FormControl} from '@mui/material';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker as BaseDatePicker} from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

const DatePicker = ({...props}) => {
  const [field, meta, helper] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';

  const handleChange = (value) => {
    helper.setValue(value);
  };

  return (
    <FormControl sx={{flex: 1, width: '100%'}}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <BaseDatePicker
          minDate={moment()}
          {...props}
          value={field.value ? moment(field.value) : null}
          format='YYYY-MM-DD'
          onChange={handleChange}
          slotProps={{
            textField: {
              helperText: errorText,
              error: !!errorText,
              sx: {
                '.MuiFormHelperText-root': {ml: 0},
                '.MuiFormLabel-root': {zIndex: 0},
              },
            },
          }}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default DatePicker;

DatePicker.propTypes = {};
