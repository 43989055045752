import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  READ_TICKET,
  READ_TICKET_MESSAGE,
  CREATE_TICKET,
  CREATE_TICKET_MESSAGE,
  DETAIL_TICKET,
  DELETE_TICKET,
  UPDATE_TICKET,
} from 'shared/constants/ActionTypes';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {TICKET} from 'shared/constants/ApiUrl';
import {createRouteParams} from 'utils';

export const onGetTicketData = (params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(
        `${TICKET}?${createRouteParams({
          sortOrder: 'created_at',
          ...(params ? params : {}),
        })}`,
      )
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: READ_TICKET, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetTicketMessageData = (id, params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(`${TICKET}/${id}/message?${createRouteParams(params)}`)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: READ_TICKET_MESSAGE, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onPostTicketData = (params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .post(`${TICKET}`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: CREATE_TICKET});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.message,
          });

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onPostTicketMessageData = (ticketId, params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .post(`${TICKET}/${ticketId}/message`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: CREATE_TICKET_MESSAGE});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetDetailTicketData = (id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(`${TICKET}/${id}`)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: DETAIL_TICKET, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onDeleteTicketData = (id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .delete(`${TICKET}/${id}`)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: DELETE_TICKET});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.message,
          });

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onUpdateTicketData = (id, params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .put(`admin/${TICKET}/${id}`, params)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: UPDATE_TICKET});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.message,
          });

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};
