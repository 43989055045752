import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Order from './Order';
import Payment from './Payment';
import Claim from './Claim';
import Ticket from './Ticket';
import Misc from './Misc';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common,
    order: Order,
    payment: Payment,
    claim: Claim,
    ticket: Ticket,
    misc: Misc,
  });
export default reducers;
