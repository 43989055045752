import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  READ_ORDER,
  CREATE_ORDER,
  DETAIL_ORDER,
  UPDATE_ORDER,
  DELETE_ORDER,
  DETAIL_ORDER_STATUS,
  DETAIL_ESTIMATE_PRICE,
  GET_RECEIPT,
  DETAIL_CUSTUMER_PROFIT,
} from 'shared/constants/ActionTypes';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {ORDER, ORDER_V2} from 'shared/constants/ApiUrl';
import {createRouteParams} from 'utils';

export const onGetOrderData = (params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(
        `${ORDER}?${createRouteParams({
          sortOrder: 'created_at',
          ...(params ? params : {}),
        })}`,
      )
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: READ_ORDER, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onPostOrderData = (params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .post(`${ORDER}`, params)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: CREATE_ORDER});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.message,
          });

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetDetailOrderData = (id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(`${ORDER}/${id}`)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: DETAIL_ORDER, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onUpdateOrderData = (id, params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .put(`${ORDER}/${id}`, params)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: UPDATE_ORDER});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.message,
          });

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onDeleteOrderData = (id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .delete(`${ORDER}/${id}`)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: DELETE_ORDER});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.message,
          });

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetDetailOrderStatusData = (id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(`${ORDER}/${id}/milestones`)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          const [firstStatus, ...otherStatus] = data?.data ?? [];

          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: DETAIL_ORDER_STATUS,
            payload: [
              {
                ...firstStatus,
                status:
                  firstStatus?.color === 2
                    ? 'Telah Dikonfirmasi'
                    : firstStatus?.status,
              },
              ...otherStatus,
            ],
          });

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetEstimateOrderPrice = (type, params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .post(`${ORDER_V2}/price_estimation/${type}`, params)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: DETAIL_ESTIMATE_PRICE, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetDefaultCustomerProfit = (params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .post(`${ORDER_V2}/profit_estimation/fcl`, params)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: DETAIL_CUSTUMER_PROFIT, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetReceiptOrder = (order_no) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(`${ORDER}/cek-resi/${order_no}`)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_RECEIPT, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};
