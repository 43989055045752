import React from 'react';

import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';

import {
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';

import {Dropzone, TextField, RadioField} from 'components';
import {PAYMENT_METHOD} from 'shared/constants/AppDataConst';
import {grey} from '@mui/material/colors';
import {PAYMENT_VALIDATION} from 'shared/validationShema';
import {moneyFormat} from 'utils';

const Alert = ({onSubmit}, ref) => {
  const [config, setConfig] = React.useState({
    id: null,
    price: null,
    show: false,
  });

  React.useImperativeHandle(ref, () => ({
    show: (newConfig) => {
      setConfig({
        ...newConfig,
        show: true,
      });
    },
    hide: handleClose,
  }));

  const handleClose = () => {
    setConfig((prev) => ({...prev, show: false}));
  };

  const handleOnSubmit = (data) => {
    if (typeof onSubmit === 'function') onSubmit(config, data);
  };

  return (
    <Dialog onClose={handleClose} open={config.show} fullWidth={true}>
      <Formik
        validateOnChange={true}
        onSubmit={handleOnSubmit}
        onReset={handleClose}
        validationSchema={PAYMENT_VALIDATION}
        initialValues={{
          payment_method: 1,
          total_payment: '',
          file: [],
        }}
      >
        {() => (
          <Form sx={{textAlign: 'left'}} noValidate autoComplete='off'>
            <DialogTitle
              sx={{
                fontSize: 16,
                px: 5,
                fontWeight: 600,
                borderBottom: 1,
                borderColor: grey[300],
              }}
            >
              Pembayaran
            </DialogTitle>
            <DialogContent sx={{p: 0}}>
              <Stack sx={{p: 5}}>
                <Stack spacing={2}>
                  <Typography component='label'>Nominal Tagihan</Typography>

                  <TextField
                    startAdornment='Rp.'
                    name='nominal'
                    disabled={true}
                    value={moneyFormat(Number(config?.price ?? '0'))}
                  />
                </Stack>
              </Stack>

              <Divider />

              <Stack spacing={4} sx={{p: 5}}>
                <Stack spacing={2}>
                  <Typography component='label'>Metode Pembayaran</Typography>

                  <RadioField
                    name='payment_method'
                    options={[PAYMENT_METHOD[0]]}
                  />
                </Stack>
                <Stack spacing={2}>
                  <Typography component='label'>Total Bayar</Typography>

                  <TextField
                    type='number'
                    startAdornment='Rp.'
                    name='total_payment'
                  />
                </Stack>
                <Stack spacing={2}>
                  <Typography component='label'>Bukti Transfer</Typography>

                  <Dropzone name='file' />
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions sx={{px: 5, borderTop: 1, borderColor: grey[300]}}>
              <Button type='reset' color='secondary'>
                Batal
              </Button>
              <Button type='submit'>Submit</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default React.forwardRef(Alert);

Alert.propTypes = {
  onSubmit: PropTypes.func,
};
