import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  READ_ROUTE,
  READ_COMMODITY,
  READ_SHIP,
  CREATE_CONTACT,
  READ_SHIPING,
} from 'shared/constants/ActionTypes';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {
  ROUTE,
  COMMODITY,
  SHIP,
  SHIPING,
  CONTACT,
  VEHICLE_TYPES,
  COSTUMER,
  DISTRICT,
} from 'shared/constants/ApiUrl';
import {createRouteParams} from 'utils';

export const onGetRouteData = (params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(`${ROUTE}?${createRouteParams(params)}`)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: READ_ROUTE, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetShipingScheduleData = (params) => {
  const finalParams = {
    ...(params ?? {}),
    sortOrder: 'created_at',
    sortBy: 'desc',
    limit: 100,
    page: 1,
  };

  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(`${SHIPING}?${createRouteParams(finalParams)}`)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: READ_SHIPING, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetCommodityData = (params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(`${COMMODITY}?${createRouteParams(params)}`)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: READ_COMMODITY, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetShipData = ({route_id}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(`${SHIP}?route_id=${route_id}`)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: READ_SHIP, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onPostContactData = (params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .post(`${CONTACT}`, params)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: CREATE_CONTACT, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetRouteOriginDestinationData = ({type, ...params}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(`${ROUTE}/${type}?${createRouteParams(params)}`)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});

          return {
            ...(data ?? {}),
            data: {
              ...(data?.data ?? {}),
              rows: (data?.data?.rows ?? [])
                .filter((item) => item && item.status && item[type])
                .filter(
                  (item) =>
                    item[type + '_id'] === 6 || item[type + '_id'] === 7, // [temp] only show Jakarta and Pontianak city
                ),
            },
          };
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetRouteDetailData = (params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(`${ROUTE}/detail?${createRouteParams(params)}`)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetVehicleTypeData = (params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(`${VEHICLE_TYPES}?${createRouteParams(params)}`)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetDistrictData = ({id, ...params}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(
        `${DISTRICT}${id ? '/' + id : ''}?${createRouteParams({
          ...(params ?? {}),
          sortBy: 'desc',
          sortOrder: 'name',
          limit: 10,
          page: 1,
        })}`,
      )
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetCustomerData = (params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(
        `${COSTUMER}?${createRouteParams({
          ...(params ?? {}),
          sortBy: 'desc',
          sortOrder: 'created_at',
          limit: 10,
          page: 1,
        })}`,
      )
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};
