import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  READ_PAYMENT,
  CREATE_PAYMENT,
  DETAIL_PAYMENT,
  UPDATE_PAYMENT,
  DELETE_PAYMENT,
} from 'shared/constants/ActionTypes';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {ORDER, PAYMENT} from 'shared/constants/ApiUrl';
import {createRouteParams} from 'utils';

export const onGetPaymentData = (orderId, params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(
        `${ORDER}/${orderId}/${PAYMENT}?${createRouteParams({
          sortOrder: 'created_at',
          ...(params ? params : {}),
        })}`,
      )
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: READ_PAYMENT, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onPostPaymentData = (orderId, params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .post(`${ORDER}/${orderId}/${PAYMENT}`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: CREATE_PAYMENT});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.message,
          });

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetDetailPaymentData = (orderId, id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(`${ORDER}/${orderId}/${PAYMENT}/${id}`)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: DETAIL_PAYMENT, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onUpdatePaymentData = (orderId, id, params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .post(`${ORDER}/${orderId}/${PAYMENT}/${id}`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: UPDATE_PAYMENT});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.message,
          });

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onDeletePaymentData = (orderId, id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .delete(`${ORDER}/${orderId}/${PAYMENT}/${id}`)
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: DELETE_PAYMENT});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.message,
          });

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};
