import React from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import {Close, InsertDriveFile} from '@mui/icons-material';
import {blue, grey} from '@mui/material/colors';

const ImagePreview = ({data, onClose}) => {
  const [open, setOpen] = React.useState(false);

  const isHaveCloseFunc = typeof onClose === 'function';

  React.useEffect(() => {
    return () => {
      if (data.preview) URL.revokeObjectURL(data.preview);
    };
  }, [data.preview]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnOpenDialog = () => {
    setOpen(true);
  };

  return (
    <>
      <Box
        onClick={handleOnOpenDialog}
        sx={{
          borderRadius: 1,
          border: '1px solid #eaeaea',
          height: '100%',
          cursor: 'pointer',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            minWidth: 0,
            overflow: 'hidden',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            padding: 2,
          }}
        >
          {data.type === 'application/pdf' ? (
            <InsertDriveFile sx={{color: blue[600], fontSize: 64}} />
          ) : (
            <Box
              component='img'
              src={
                typeof data === 'string'
                  ? data
                  : data?.preview ?? data?.attachments
              }
              sx={{display: 'block', width: 'auto', height: '100%'}}
            />
          )}

          {isHaveCloseFunc && (
            <IconButton
              onClick={onClose}
              sx={{position: 'absolute', top: 0, right: 0, p: 2}}
            >
              <Close />
            </IconButton>
          )}
        </Box>
      </Box>

      <Dialog onClose={handleClose} open={open} fullWidth={true}>
        <DialogTitle
          sx={{
            fontSize: 16,
            px: 5,
            fontWeight: 600,
            borderBottom: 1,
            borderColor: grey[300],
          }}
        >
          Pratinjau
        </DialogTitle>
        <DialogContent sx={{p: 0}}>
          <Box
            component='img'
            src={
              typeof data === 'string'
                ? data
                : data?.preview ?? data?.attachments
            }
            sx={{display: 'block', width: 'auto', height: '100%'}}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImagePreview;

ImagePreview.propTypes = {
  onClose: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
