import {
  READ_ROUTE,
  READ_COMMODITY,
  READ_SHIP,
  READ_SHIPING,
} from 'shared/constants/ActionTypes';

const initialState = {
  routeList: [],
  commodityList: [],
  shipList: [],
  shipingList: [],
};

const miscReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_ROUTE:
      return {
        ...state,
        routeList: action.payload,
      };
    case READ_COMMODITY:
      return {
        ...state,
        commodityList: action.payload,
      };
    case READ_SHIP:
      return {
        ...state,
        shipList: action.payload,
      };
    case READ_SHIPING:
      return {
        ...state,
        shipingList: action.payload,
      };

    default:
      return state;
  }
};
export default miscReducer;
