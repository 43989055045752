import React from 'react';

const Dashboard = React.lazy(() => import('./Dashboard'));
const Order = React.lazy(() => import('./Order'));
const AddOrder = React.lazy(() => import('./Order/Add'));
const DetailOrder = React.lazy(() => import('./Order/Detail'));
const Payment = React.lazy(() => import('./Payment'));
const EditPayment = React.lazy(() => import('./Payment/Edit'));
const DetailPayment = React.lazy(() => import('./Payment/Detail'));
const ListPayment = React.lazy(() => import('./Payment/List'));
const Claim = React.lazy(() => import('./Claim'));
const AddClaim = React.lazy(() => import('./Claim/Add'));
const EditClaim = React.lazy(() => import('./Claim/Edit'));
const DetailClaim = React.lazy(() => import('./Claim/Detail'));
const Ticket = React.lazy(() => import('./Ticket'));
const AddTicket = React.lazy(() => import('./Ticket/Add'));
const DetailTicket = React.lazy(() => import('./Ticket/Detail'));
const Report = React.lazy(() => import('./Report'));
const ReportDetail = React.lazy(() => import('./Report/Detail'));
const Account = React.lazy(() => import('./Account'));

import routeUrl from 'shared/constants/RouteUrl';

export const authorizedRouteConfig = [
  {
    path: routeUrl.dashboard,
    element: <Dashboard />,
  },
  {
    path: routeUrl.order,
    element: <Order />,
  },
  {
    path: routeUrl.addOrder,
    element: <AddOrder />,
  },
  {
    path: routeUrl.detailOrder + '/:id',
    element: <DetailOrder />,
  },
  {
    path: routeUrl.payment,
    element: <Payment />,
  },
  {
    path: routeUrl.detailPayment + '/:orderId',
    element: <ListPayment />,
  },
  {
    path: routeUrl.editPayment + '/:orderId/edit/:paymentId',
    element: <EditPayment />,
  },
  {
    path: routeUrl.detailPayment + '/:orderId/detail/:paymentId',
    element: <DetailPayment />,
  },
  {
    path: routeUrl.claim,
    element: <Claim />,
  },
  {
    path: routeUrl.addClaim,
    element: <AddClaim />,
  },
  {
    path: routeUrl.editClaim + '/:id',
    element: <EditClaim />,
  },
  {
    path: routeUrl.detailClaim + '/:id',
    element: <DetailClaim />,
  },
  {
    path: routeUrl.ticket,
    element: <Ticket />,
  },
  {
    path: routeUrl.addTicket,
    element: <AddTicket />,
  },
  {
    path: routeUrl.detailTicket + '/:id',
    element: <DetailTicket />,
  },
  {
    path: routeUrl.history,
    element: <Report />,
  },
  {
    path: routeUrl.history + '/:id',
    element: <ReportDetail />,
  },
  {
    path: routeUrl.account,
    element: <Account />,
  },
];
