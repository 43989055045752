const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
  },
  {
    languageId: 'indonesia',
    locale: 'id',
    name: 'Indonesia',
  },
];
export default languageData;
