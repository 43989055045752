//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';

//ORDER
export const READ_ORDER = 'read_order';
export const CREATE_ORDER = 'create_order';
export const DETAIL_ORDER_STATUS = 'detail_order_status';
export const DETAIL_ESTIMATE_PRICE = 'detail_estimate_price';
export const DETAIL_CUSTUMER_PROFIT = 'detail_customer_profit';
export const GET_RECEIPT = 'get_receipt';
export const DETAIL_ORDER = 'detail_order';
export const UPDATE_ORDER = 'update_order';
export const DELETE_ORDER = 'delete_order';

//PAYMENT
export const READ_PAYMENT = 'READ_PAYMENT';
export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const DETAIL_PAYMENT = 'DETAIL_PAYMENT';
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const DELETE_PAYMENT = 'DELETE_PAYMENT';

//CLAIM
export const READ_CLAIM = 'read_claim';
export const CREATE_CLAIM = 'create_claim';
export const DETAIL_CLAIM = 'detail_claim';
export const UPDATE_CLAIM = 'update_claim';
export const DELETE_CLAIM = 'delete_claim';

//TICKET
export const READ_TICKET = 'read_ticket';
export const READ_TICKET_MESSAGE = 'read_ticket_message';
export const CREATE_TICKET = 'create_ticket';
export const CREATE_TICKET_MESSAGE = 'create_ticket_message';
export const DETAIL_TICKET = 'detail_ticket';
export const UPDATE_TICKET = 'update_ticket';
export const DELETE_TICKET = 'delete_ticket';

//HISTORY
export const READ_HISTORY = 'read_history';
export const DETAIL_HISTORY = 'detail_history';
export const CREATE_EVIDENCE = 'create_evidence';

//MISC
export const READ_ROUTE = 'read_route';
export const READ_COMMODITY = 'read_commodity';
export const READ_SHIP = 'read_ship';
export const READ_SHIPING = 'read_shiping';
export const CREATE_CONTACT = 'create_contact';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';
