import React from 'react';

import {useField} from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useDispatch} from 'react-redux';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Typography,
  Stack,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CalendarIcon from '@mui/icons-material/Today';

import {TextField, Calender} from 'components';
import {onGetShipingScheduleData} from 'redux/actions';

const ShipModal = ({route_id, ...props}) => {
  const dispatch = useDispatch();

  const [field, , helper] = useField(props);
  const [open, setOpen] = React.useState(false);
  const [openEvent, setOpenEvent] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [selectedEvent, setSelectedEvent] = React.useState({});

  React.useEffect(() => {
    const handleFetchShipingSchedule = async () => {
      if (!route_id) {
        setEvents([]);
        return;
      }

      const response = await dispatch(
        onGetShipingScheduleData({route_id: route_id}),
      );
      const {rows} = response?.data ?? {};

      setEvents(
        rows.map((item) => ({
          id: item.id,
          date: moment(item.etd).format('YYYY-MM-DD'),
          data: item,
        })),
      );
    };

    if (route_id && open) {
      handleFetchShipingSchedule();
    }
  }, [route_id, open]);

  const handleCancel = () => {
    helper.setValue(field.value.length > 1 ? field.value : '');
    setOpen(false);
  };

  const handleCloseEvent = () => {
    setOpenEvent(false);
  };

  const handleShow = () => {
    if (props?.disabled) {
      return;
    }

    setOpen(true);
    helper.setValue(moment(field?.value ?? new Date()).format('YYYY-MM-DD'));
  };

  const handleOnEventClick = (info) => {
    const selectedData = events.find((item) => item.id === info.event.id);

    setSelectedEvent({
      id: info.event.id,
      title: `${selectedData?.data?.ship?.name ?? ''} - ${
        selectedData?.data?.cruiser?.name ?? ''
      }`,
      start: selectedData?.data?.etd ?? '-',
      end: selectedData?.data?.eta ?? '-',
      data: {
        ETD: selectedData?.data?.etd ?? '-',
        'Open Stack': selectedData?.data?.open_stack ?? '-',
        'Closing RC': selectedData?.data?.closing_rc ?? '-',
        'Closing Fisik': selectedData?.data?.closing_fisik ?? '-',
        'Stack Full': selectedData?.data?.stack_full ?? '-',
        ETA: selectedData?.data?.eta ?? '-',
      },
    });
    setOpenEvent(true);
  };

  const handleOnDateClick = (event) => {
    helper.setValue(event.dateStr);
    setOpen(false);
  };

  const renderEventDetailItem = (item, key) => {
    return (
      <Grid item xs={12} key={`item-${key}`}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography component='h6' fontSize={12}>
              {item}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography component='h6' fontSize={12}>
              :
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography component='h6' fontSize={12}>
              {selectedEvent?.data[item]
                ? moment(selectedEvent?.data[item]).format('DD MMMM YYYY')
                : '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <TextField
        {...props}
        readOnly={true}
        onClick={handleShow}
        endAdornment={<CalendarIcon />}
      />

      <Dialog open={open} fullWidth={true}>
        <DialogTitle
          color='primary'
          textTransform='uppercase'
          sx={{fontSize: 16, px: 4, backgroundColor: 'rgb(243, 253, 255)'}}
        >
          Pilih Jadwal Kapal
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{pb: 6}}>
          <Calender
            onDateClick={handleOnDateClick}
            onEventClick={handleOnEventClick}
            events={events}
            initialDate={field.value}
            sx={`
              .fc-daygrid-day-events {
                display: flex;
                flex-wrap: wrap;
                padding: 0 8px;
              }
              .fc-daygrid-event {
                cursor: pointer;
                width: 12px;
                height: 12px;
                border-radius: 12px;
              }
              td[data-date="${field.value}"] {
                background-color: rgb(243, 253, 255) !important;
              }
            `}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openEvent} fullWidth={true} maxWidth='xs'>
        <DialogTitle
          color='primary'
          textTransform='uppercase'
          sx={{fontSize: 14, px: 4, backgroundColor: 'rgb(243, 253, 255)'}}
        >
          Event Detail
        </DialogTitle>
        <Stack
          direction={'row'}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <IconButton
            aria-label='close'
            onClick={handleCloseEvent}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <DialogContent sx={{pb: 6}}>
          <Stack divider={<Divider orientation='horizontal' />} spacing={5}>
            <Stack>
              <Typography component='h6' fontSize={15} fontWeight={600}>
                {selectedEvent?.title ?? '-'}
              </Typography>
              <Typography component='h6' fontSize={10}>
                {`${
                  selectedEvent?.start
                    ? moment(selectedEvent?.start).format('DD MMM YYYY')
                    : ' '
                } - ${
                  selectedEvent?.end
                    ? moment(selectedEvent?.end).format('DD MMM YYYY')
                    : ' '
                }`}
              </Typography>
            </Stack>

            <Grid container rowSpacing={1}>
              {Object.keys(selectedEvent?.data ?? {}).map(
                renderEventDetailItem,
              )}
            </Grid>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShipModal;

ShipModal.propTypes = {
  route_id: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
