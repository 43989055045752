import * as yup from 'yup';

const rateValidationSchema = yup.object({
  order_type: yup.string().nullable().required('Field wajib diisi'),
  order_term: yup.string().nullable().required('Field wajib diisi'),
  sender_commodities: yup.array().when('order_type', (order_type, schema) =>
    schema.of(
      yup.object({
        commodity_id: yup.object().nullable().required('Field wajib diisi'),
        qty:
          order_type == 1
            ? yup.string().required('Field wajib diisi')
            : yup.string(),
        long:
          order_type == 2
            ? yup.string().required('Field wajib diisi')
            : yup.string(),
        width:
          order_type == 2
            ? yup.string().required('Field wajib diisi')
            : yup.string(),
        tall:
          order_type == 2
            ? yup.string().required('Field wajib diisi')
            : yup.string(),
        container_type:
          order_type == 1
            ? yup.object().nullable().required('Field wajib diisi')
            : yup.object().nullable(),
        quarantined: yup.bool(),
        quarantine_weight: yup.string().required('Field wajib diisi'),
      }),
    ),
  ),
  // route_id: yup.object().nullable().required('Field wajib diisi'),
  origin_id: yup.object().nullable().required('Field wajib diisi'),
  destination_id: yup.object().nullable().required('Field wajib diisi'),
});

export default rateValidationSchema;
