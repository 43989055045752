import React from 'react';

import {Form, Formik} from 'formik';

import {
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import {TextField, RadioField} from 'components';
import {PAYMENT_METHOD} from 'shared/constants/AppDataConst';
import {grey} from '@mui/material/colors';
import {moneyFormat} from 'utils';

const HistoryDetailDialog = (_, ref) => {
  const [config, setConfig] = React.useState({
    id: null,
    price: null,
    show: false,
  });

  React.useImperativeHandle(ref, () => ({
    show: (newConfig) => {
      setConfig({
        ...newConfig,
        show: true,
      });
    },
    hide: handleClose,
  }));

  const handleClose = () => {
    setConfig((prev) => ({...prev, show: false}));
  };

  return (
    <Dialog onClose={handleClose} open={config.show} fullWidth={true}>
      <Formik validateOnChange={true}>
        {() => (
          <Form sx={{textAlign: 'left'}} noValidate autoComplete='off'>
            <DialogTitle
              sx={{
                fontSize: 16,
                px: 5,
                fontWeight: 600,
                borderBottom: 1,
                borderColor: grey[300],
              }}
            >
              Detail Pembayaran
            </DialogTitle>
            <DialogContent sx={{p: 0}}>
              <Stack spacing={4} sx={{p: 5}}>
                <Stack spacing={2}>
                  <Typography component='label'>Termin</Typography>

                  <TextField
                    name='termin'
                    disabled={true}
                    value={String(config?.termin ?? 0)}
                  />
                </Stack>

                <Stack spacing={2}>
                  <Typography component='label'>Metode Pembayaran</Typography>

                  <RadioField
                    disabled={true}
                    name='payment_method'
                    options={PAYMENT_METHOD}
                    value={config?.payment_method ?? 1}
                  />
                </Stack>
                <Stack spacing={2}>
                  <Typography component='label'>Total Bayar</Typography>

                  <TextField
                    startAdornment='Rp.'
                    name='paid_amount'
                    disabled={true}
                    value={moneyFormat(Number(config?.paid_amount ?? '0'))}
                  />
                </Stack>
                <Stack spacing={2}>
                  <Typography component='label'>Bukti Pembayaran</Typography>

                  <Stack
                    borderRadius={2}
                    minHeight={100}
                    overflow='hidden'
                    bgcolor='#fafafa'
                  >
                    {config?.file ? (
                      <img
                        src={config?.file}
                        style={{verticalAlign: 'middle'}}
                      />
                    ) : (
                      <Typography textAlign='center' pt={8}>
                        Tidak Bukti Pembayaran
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </DialogContent>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default React.forwardRef(HistoryDetailDialog);
