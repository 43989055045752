import * as yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const contactValidationSchema = yup.object({
  company_name: yup.string().required('Field wajib diisi'),
  phone_number: yup
    .string()
    .required('Field wajib diisi')
    .matches(phoneRegExp, 'Nomor telepon tidak valid'),
  message: yup.string().required('Field wajib diisi'),
});

export default contactValidationSchema;
