import React from 'react';

import PropTypes from 'prop-types';

import {Stack, Card} from '@mui/material';

const CardField = ({children}) => {
  return (
    <Card sx={{borderRadius: 2, overflow: 'visible'}}>
      <Stack sx={{p: {xs: 6, sm: 7}}} spacing={{xs: 4, md: 6}}>
        {children}
      </Stack>
    </Card>
  );
};

export default CardField;

CardField.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};
